import { graphql, useStaticQuery } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import React from 'react';
import icon from '../images/location.svg';
import SectionHeader from './SectionHeader';
import loadable from '@loadable/component';
const Image = loadable(() => import('./Image'));

const query = graphql`
  query {
    image: file(relativePath: { eq: "venue.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    sub1: file(relativePath: { eq: "location-1.png" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    sub2: file(relativePath: { eq: "location-2.png" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default function Location(props) {
  const { image, sub1, sub2 } = useStaticQuery(query);

  return (
    <section data-theme="blue" id="location" style={{ overflow: 'hidden' }}>
      <div style={{ position: 'relative' }}>
        <BackgroundImage
          className='fixed-background'
          style={{ height: '60vh', objectFit: 'cover' }}
          fluid={image.childImageSharp.fluid}
        />
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'hsla(208, 89%, 18%, 0.5)',
          }}
        />
      </div>
      <br />

      <div className="container">
        <SectionHeader icon={icon} to="/#location" title="Location" content="Where is what?" />
        <div className="divider" />
        <br />
        <div className="row">
          <div className="col-6">
            <h2>Station 3 Venue</h2>
            <a target="__blank" href="https://goo.gl/maps/GYS5zYmMB8cwDhgj6">
              <p>
                1919 Houston Ave
                <br />
                Houston, TX 77007
              </p>
            </a>
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <p>
              Station 3, otherwise known as "Stonewall", was one of Houston's original fire
              stations. Built in 1903, its everlasting motto, "Trust in Us" can still be seen etched
              into the buildings stone today. In the beginning, Station 3 firefighters used to put
              out Houston's hot flames using horse drawn wagons and steamers!
            </p>

            <p>
              Station 3 is one of the cities historical landmarks, located directly outside of
              downtown. The original brick, wood floors, concrete beams, and intricate hand carved
              exterior stone work all add to its timeless beauty and charm.
            </p>

            <p>
              Station 3 underwent a complete restoration project in 2014, bringing many of its
              amenities into the 21st century.
            </p>
            <p>
              for more information visit{' '}
              <a href="http://www.houstonfirestation.com/about">Houston fire station</a>
            </p>
          </div>
          <div className="col-6">
            <main className="image">
              <Image fluid={sub1.childImageSharp.fluid} />
              <p>
                This photo of the firefighters was taken when the station was still in use
              </p>
            </main>
          </div>
        </div>
      </div>
    </section>
  );
}
