// Load the required clients and packages
import { CognitoIdentityClient } from '@aws-sdk/client-cognito-identity';
import { fromCognitoIdentityPool } from '@aws-sdk/credential-provider-cognito-identity';
import {
  S3Client,
  PutObjectCommand,
  ListObjectsCommand,
  DeleteObjectCommand,
} from '@aws-sdk/client-s3';
import { nanoid } from 'nanoid';

// Set the AWS Region
const REGION = 'us-east-2'; //REGION

// Initialize the Amazon Cognito credentials provider
const s3 = new S3Client({
  region: REGION,
  credentials: fromCognitoIdentityPool({
    client: new CognitoIdentityClient({ region: REGION }),
    identityPoolId: process.env.GATSBY_S3_IDENTITY_POOL_ID, // IDENTITY_POOL_ID
  }),
});

const albumBucketName = 'wedding-tweets'; //BUCKET_NAME

// Add a photo to an album
export async function addPhoto(file) {
  const fileName = `${nanoid()}-${file.name}`;
  const photoKey = fileName;
  const uploadParams = {
    ACL: 'public-read',
    Bucket: albumBucketName,
    Key: photoKey,
    Body: file,
  };

  return s3
    .send(
      new ListObjectsCommand({
        Prefix: '/',
        Bucket: albumBucketName,
      })
    )
    .then(() => s3.send(new PutObjectCommand(uploadParams)))
    .then(() => `https://wedding-tweets.s3.us-east-2.amazonaws.com/${fileName}`);
}
