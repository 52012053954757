import { graphql, useStaticQuery } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import React from 'react';
import loadable from '@loadable/component';
const Mason = loadable(() => import('react-stone-mason'));
import KittiesMeet from '../images/kitties.svg';
import icon from '../images/our-story-icon.svg';
const Image = loadable(() => import('./Image'));
import SectionHeader from './SectionHeader';
import Barbara from '../images/barbara.svg';
import Patches from '../images/patches.svg';
import Pacha from '../images/pacha.svg';

const query = graphql`
  {
    allOurStoryYaml {
      nodes {
        id
        title
        content
        size
        insert
        images {
          title
          link
          size
          style {
            minHeight
            objectPosition
          }
          src {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    allFile(sort: { fields: name }, filter: { relativeDirectory: { eq: "engagement" } }) {
      nodes {
        id
        childImageSharp {
          fluid(maxWidth: 1080) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
    cover: file(relativePath: { eq: "ourstory-cover.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

const parseLinks = (text = '') =>
  text.replace(/\[(((?!\]).)*)\]\((((?!\)).)*)\)/g, `<a target='__blank' href='$3'>$1</a>`);

export default function OurStory(props) {
  const data = useStaticQuery(query);

  const sections = data.allOurStoryYaml.nodes;
  const engagementPhotos = data.allFile.nodes;
  const { cover, bottomCover } = data;

  const inserts = {
    kitties: (
      <div style={{ textAlign: 'center' }}>
        <Barbara />
        <Pacha />
        <Patches />
      </div>
    ),
    kitties_meet: (
      <div style={{ textAlign: 'center' }}>
        <KittiesMeet style={{ maxWidth: '100%' }} />
      </div>
    ),
    engagement_gallery:
      typeof window === 'undefined' ? null : (
        <Mason
          columns={{
            mobile: { query: '(min-width: 33.75em', columns: 1 },
            tablet: { query: '(min-width: 45em)', columns: 2 },
            desktop: { query: '(min-width: 60em)', columns: 3 },
          }}
        >
          {engagementPhotos.map(photo => (
            <Image key={photo.id} style={{ margin: '10px' }} fluid={photo.childImageSharp.fluid} />
          ))}
        </Mason>
      ),
  };

  return (
    <section data-theme="orange" id="story">
      <div style={{ position: 'relative' }}>
        <BackgroundImage
          className="fixed-background"
          style={{
            height: '80vh',
            backgroundSize: 'cover',
            backgroundPosition: '60% 95%',
          }}
          fluid={cover.childImageSharp.fluid}
        />
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'hsla(4, 93%, 17%, 0.5)',
          }}
        />
      </div>
      <div className="container">
        <SectionHeader icon={icon} to="/#story" title="Our Story" content="Why are we here?" />
        {sections.map(section => (
          <React.Fragment key={section.id}>
            <div className="divider" />
            <br />
            <div className="row">
              <div className={`col-${section.size ?? 6}`} key={section.id}>
                <h3>{section.title}</h3>
                {section.content &&
                  section.content
                    .split('\n')
                    .map(parseLinks)
                    .map((__html, key) => <p key={key} dangerouslySetInnerHTML={{ __html }} />)}
                {section.insert && inserts[section.insert]}
              </div>
              {section?.images?.map((image, i) => (
                <main key={i} className={`col-${image.size ?? 6} image`}>
                  <Image
                    fluid={image.src.childImageSharp.fluid}
                    style={image.style}
                    imgStyle={image.style}
                  />
                  <p>
                    {image.title}
                    <br />
                    {image.link && (
                      <i>
                        <span dangerouslySetInnerHTML={{ __html: parseLinks(image.link) }} />
                      </i>
                    )}
                  </p>
                </main>
              ))}
            </div>
          </React.Fragment>
        ))}
      </div>
      <br />
    </section>
  );
}
