import React, { useState } from 'react';
import { searchUser } from '../app/api';
import store from '../app/store';
import Button from './Button';
import Form from './Form';
import Modal from './Modal';

export function authenticateGuest(query) {
  if (!query) return Promise.reject();
  let params = new URLSearchParams(location.search);
  if (!params.get('email') || params.get('email') !== query) {
    if (typeof 'window' !== undefined) {
      window.history.replaceState({}, 'Let Sparks Fly', `/?email=${encodeURIComponent(query)}`);
    }
  }
  return searchUser(query);
}

export default function TryEmailModal({ onCancel, onAuthenticated = () => {} }) {
  const [email, setEmail] = useState('');
  const [error, setError] = useState(null);
  const [user, setUser] = store.use('user');

  return (
    <Modal open layoutId="rsvp">
      <Modal.Content>
        <h2>{error ? 'Apologies' : 'Hmmm, Have we met?'}</h2>
        <p>
          {error
            ? `We were unable to find you in the system.`
            : `Sorry, I don't recognize you. maybe we can find you with your email address?`}
        </p>
        <Form.Input label="Email" value={email} onChange={e => setEmail(e.target.value)} />
        <Modal.Actions>
          <Button
            primary
            content="Try email"
            onClick={() =>
              authenticateGuest(email).then(setUser).then(onAuthenticated).catch(setError)
            }
          />
          <Button content="Cancel" onClick={onCancel} />
        </Modal.Actions>
      </Modal.Content>
    </Modal>
  );
}
