import loadable from '@loadable/component';
import { differenceInHours, formatDistance } from 'date-fns';
import { AnimateSharedLayout } from 'framer-motion';
import { graphql } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import Img from 'gatsby-image';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { getSiteConfig } from '../app/api';
import '../app/fragments';
import store from '../app/store';
import '../app/styles.scss';
import { cssBackgroundHack, isIOS, titleCase } from '../app/utils';
import AroundTown from '../components/AroundTown';
import Fab from '../components/Fab';
import GradientTransition from '../components/GradientTransition';
import HomeMenu from '../components/HomeMenu';
import '../components/layout.css';
import Location from '../components/Location';
import OurStory from '../components/OurStory';
import RsvpButton from '../components/RsvpButton';
import Schedule from '../components/Schedule';
import WeddingParty from '../components/WeddingParty';

const DATE = Date.parse(`14 Feb 2021 14:00:00 CST`);
const Registry = loadable(() => import('../components/Registry'));
const Share = loadable(() => import('../components/Share'));

export default function IndexPage({ data, location, ...rest }) {
  const { heroImage, bottomCover } = data;
  const [rsvpState, setRsvpState] = useState('none');
  const [config, setConfig] = store.use('config');

  useEffect(() => {
    getSiteConfig().catch(console.error).then(setConfig);
  }, []);

  function onRsvp() {
    setRsvpState('rsvp');
  }

  useLayoutEffect(() => {
    if (isIOS()) {
      cssBackgroundHack();
    }
  }, []);

  return (
    <article>
      <AnimateSharedLayout>
        <div className="hero">
          <Img
            imgStyle={{ objectPosition: 'right 21%' }}
            className="hero-image"
            fluid={heroImage.childImageSharp.fluid}
          />
          <div className="content">
            <div className="text">
              <div>Don't Miss</div>
              <div className="name">Mr & Mrs Sparks</div>
            </div>
            <div className="rsvp-box">
              <div className="date">{formatEventTime()}</div>
              <div data-theme="form">
                <RsvpButton location={location} rsvpState={rsvpState} setRsvpState={setRsvpState} />
              </div>
            </div>
          </div>
        </div>
        <Fab onRsvp={onRsvp} />
      </AnimateSharedLayout>
      <HomeMenu onRsvp={onRsvp} />
      <Share />
      <Location />
      <GradientTransition from="#063c6a" to="#2c325e" />
      <WeddingParty data-theme="purple" />
      <GradientTransition from="#2c325e" to="#562a47" />
      <Schedule />
      <GradientTransition from="#562a47" to="#83243e" />
      <AroundTown />
      <OurStory />
      <div style={{ position: 'relative' }}>
        <BackgroundImage
          className="fixed-background"
          style={{
            height: '80vh',
            backgroundSize: 'cover',
            backgroundPosition: '60% 10%',
          }}
          fluid={bottomCover.childImageSharp.fluid}
        />
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'hsla(4, 93%, 17%, 0.5)',
          }}
        />
      </div>
      <Registry />
    </article>
  );
}

function formatEventTime() {
  const today = new Date();
  const dayDiff = differenceInHours(today, DATE);
  if (dayDiff === 0) return 'Today';
  if (dayDiff < 0) return titleCase(`${formatDistance(DATE, today)} away`);
  if (dayDiff > 0) return titleCase(`${formatDistance(DATE, today)} ago`);
}

export const query = graphql`
  query {
    heroImage: file(relativePath: { eq: "hero.png" }) {
      childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    bottomCover: file(relativePath: { eq: "invite-photo.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
