import { AnimatePresence, motion } from 'framer-motion';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import React, { useState } from 'react';
import icon from '../images/around-town.svg';
import PartyToggle from '../images/party-toggle.svg';
import SectionHeader from './SectionHeader';

const query = graphql`
  {
    allAroundTownYaml {
      nodes {
        address
        category
        description
        directions
        id
        name
        image {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;

const backgroundVariant = {
  visible: {
    height: '100%',
    paddingTop: '60px',
    transition: {
      when: 'beforeChildren',
      type: 'easeInOut',
    },
  },
  hidden: {
    height: '140px',
    paddingTop: '20px',
    transition: {
      when: 'afterChildren',
      type: 'easeInOut',
    },
  },
};

const contentVariant = {
  visible: {
    opacity: 1,
    transition: {
      delay: 0.25,
    },
  },
  hidden: {
    opacity: 0,
  },
};

function Block(props) {
  const { name, directions, address, category, description, image } = props;
  const [visible, setVisible] = useState(false);

  return (
    <div className="party-member" onClick={() => setVisible(v => !v)}>
      {image && <Img fluid={image.childImageSharp.fluid} />}
      <motion.div
        layout
        className="content"
        variants={backgroundVariant}
        initial="hidden"
        animate={visible ? 'visible' : 'hidden'}
      >
        <motion.h2 layout>{name}</motion.h2>
        <motion.p layout>{category}</motion.p>
        <AnimatePresence>
          {visible && (
            <>
              <motion.p layout variants={contentVariant}>
                {description}
              </motion.p>
              <motion.a layout variants={contentVariant} target="__blank" href={directions}>
                <motion.p
                  dangerouslySetInnerHTML={{ __html: address.split('\n').join('<br />') }}
                />
              </motion.a>
            </>
          )}
        </AnimatePresence>
        <motion.div
          animate={{ transform: `rotate(${visible ? 135 : 0}deg)` }}
          style={{ position: 'absolute', right: 0, bottom: 0, margin: '20px', height: '44px' }}
        >
          <PartyToggle />
        </motion.div>
      </motion.div>
    </div>
  );
}

export default function AroundTown(props) {
  const data = useStaticQuery(query);
  const places = data.allAroundTownYaml.nodes;

  return (
    <section data-theme="red" id="aroundTown">
      <br />
      <div className="container">
        <SectionHeader
          to="/#aroundTown"
          icon={icon}
          title="Around Town"
          content="How can I waste an hour?"
        />
        <div className="divider" />
        <br />
        <section className="wedding-party">
          {places.map(place => (
            <Block key={place.id} {...place} />
          ))}
        </section>
        <br />
      </div>
    </section>
  );
}
