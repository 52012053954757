import React from 'react';
import { AnchorLink } from 'gatsby-plugin-anchor-links';

export default function SectionHeader(props) {
  const { title, content, icon, to, ...rest } = props;
  const Icon = icon;

  let children = (
    <section className="section-header" {...rest}>
      {icon && <Icon />}
      <section className="content">
        <h3>{title}</h3>
        <p>{content}</p>
      </section>
    </section>
  );

  return to ? (
    <AnchorLink className="section-header-link" to={to}>
      {children}
    </AnchorLink>
  ) : (
    children
  );
}
