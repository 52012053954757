import { isWithinInterval, parseISO } from 'date-fns';
import { AnimatePresence, AnimateSharedLayout, motion } from 'framer-motion';
import { navigate } from 'gatsby';
import React, { useEffect, useState } from 'react';
import { getSiteConfig, createRsvpData, searchUser } from '../app/api';
import store from '../app/store';
import Button from '../components/Button';
import Form from '../components/Form';
import Modal from '../components/Modal';
import RsvpFlow from './rsvp';
import { LiveShareButton } from './Share';
import TryEmailModal, { authenticateGuest } from './TryEmailModal';

export default function RsvpButton({ location, rsvpState, setRsvpState }) {
  const [userRecord, setUserRecord] = store.use('user');
  const [config] = store.use('config');
  const [notes, setNotes] = useState('');

  function onAttendEvite() {
    createRsvpData({ ...userRecord, attending: 'remotely', accountHolder: true })
      .then(() => setRsvpState('accept-evite'))
      .catch(error => {
        console.error(error);
        setRsvpState('error');
      });
  }

  function onDecline() {
    createRsvpData({ ...userRecord, attending: 'no', accountHolder: true, notes })
      .then(() => {
        setRsvpState('declined-invite');
      })
      .catch(e => {
        console.error(e);
        setRsvpState('error');
      });
    // navigate to registry to make them feel bad
  }

  useEffect(() => {
    if (rsvpState !== 'none') return;
    let params = new URLSearchParams(location.search);
    authenticateGuest(params.get('email'))
      .then(setUserRecord)
      .catch(() => {});
  }, [location.search, rsvpState]);

  const isVideoLive = config?.facebookEmbedCode;
  // &&
  // isWithinInterval(new Date(), {
  //   start: parseISO(config.streamShowAt),
  //   end: parseISO(config.streamHideAt),
  // });

  const showVideo = userRecord && isVideoLive;

  let rsvpButtonContent = userRecord?.guests?.length ? 'Update RSVP' : 'RSVP';
  if (isVideoLive) {
    rsvpButtonContent = 'Watch';
  }

  return (
    <AnimateSharedLayout>
      <motion.div layoutId="rsvp" className="rsvp-button">
        <Button content={rsvpButtonContent} onClick={() => setRsvpState('rsvp')} />
      </motion.div>
      <LiveShareButton />
      <AnimatePresence>
        {showVideo && (
          <>
            <motion.div
              animate={{ opacity: 1 }}
              initial={{ opacity: 0 }}
              exit={{ opacity: 0 }}
              className="modal-container"
              style={{ position: 'absolute' }}
            />
            <motion.div
              className="video-container"
              style={{ zIndex: 101 }}
              initial={{ opacity: '0%' }}
              transition={{ duration: 1 }}
              animate={{ opacity: '100%' }}
            >
              <div className="video-inner-container">
                <iframe
                  src={config?.facebookEmbedCode}
                  className="video"
                  style={{ aspectRatio: '16 / 9' }}
                  scrolling="no"
                  allowFullScreen
                  allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                />
              </div>
            </motion.div>
          </>
        )}
        {rsvpState === 'rsvp' && !userRecord && (
          <TryEmailModal onCancel={() => setRsvpState('none')} />
        )}
        {rsvpState === 'rsvp' && userRecord && config.rsvpForceStream && (
          <Modal open layoutId="rsvp">
            <Modal.Content>
              <h2>Welcome {userRecord.Name}!</h2>
              <p>
                Due to COVID-19 we are limiting in-person attendence.
                <br />
                Would you like to attend via livestream?
              </p>
              <Modal.Actions>
                <Button primary content="Yes!" onClick={onAttendEvite} />
                <Button
                  content="Decline invitation"
                  onClick={() => setRsvpState('decline-offer-evite')}
                />
              </Modal.Actions>
            </Modal.Content>
          </Modal>
        )}
        {rsvpState === 'rsvp' &&
          !config.rsvpForceStream &&
          userRecord &&
          !userRecord?.guests.length && (
            <Modal open layoutId="rsvp">
              <Modal.Content>
                <h2>Welcome {userRecord.Name}!</h2>
                <p>Are you planning on attending the wedding?</p>
                <Modal.Actions>
                  <Button
                    primary
                    content="Yes!"
                    onClick={() => setRsvpState('accept-offer-evite')}
                  />
                  <Button
                    content="Decline invitation"
                    onClick={() => setRsvpState('decline-offer-evite')}
                  />
                </Modal.Actions>
              </Modal.Content>
            </Modal>
          )}
        {rsvpState === 'accept-offer-evite' && userRecord && (
          <Modal open layoutId="rsvp">
            <Modal.Content>
              <h2>Livestream available</h2>
              <p>Would you prefer to attend via livestream?</p>
              <Modal.Actions>
                <Button primary content="Sure" onClick={onAttendEvite} />
                <Button content="No thanks" onClick={() => setRsvpState('in-person')} />
              </Modal.Actions>
            </Modal.Content>
          </Modal>
        )}
        {rsvpState === 'decline-offer-evite' && userRecord && (
          <Modal open layoutId="rsvp">
            <Modal.Content>
              <h2>Livestream Available</h2>
              <p>Would you like to attend via livestream?</p>
              <Modal.Actions>
                <Button primary content="Sure" onClick={onAttendEvite} />
                <Button content="No thanks" onClick={() => setRsvpState('decline')} />
              </Modal.Actions>
            </Modal.Content>
          </Modal>
        )}
        {rsvpState === 'accept-evite' && userRecord && (
          <Modal open layoutId="rsvp">
            <Modal.Content>
              <h2>Great!</h2>
              <p>We'll send you a reminder email for the livestream on the big day</p>
              <Modal.Actions>
                <Button primary content="Okay" onClick={() => setRsvpState('none')} />
              </Modal.Actions>
            </Modal.Content>
          </Modal>
        )}
        {rsvpState === 'username-error' && (
          <Modal open layoutId="rsvp">
            <Modal.Content>
              <h2>Apologies</h2>
              <p>
                We were unable to find you in the system <br /> Please reach out to us for help.
              </p>
              <Modal.Actions>
                <Button content="Dismiss" onClick={() => setRsvpState('none')} />
              </Modal.Actions>
            </Modal.Content>
          </Modal>
        )}
        {rsvpState === 'decline' && (
          <Modal open layoutId="rsvp">
            <Modal.Content>
              <h2>Sorry we won't get to see you!</h2>
              <Form.TextArea
                label="Any notes?"
                value={notes}
                onChange={e => setNotes(e.target.value)}
              />
              <Modal.Actions>
                <Button primary content="Send Notes" onClick={onDecline} />
                <Button content="Nope" onClick={onDecline} />
              </Modal.Actions>
            </Modal.Content>
          </Modal>
        )}
        {rsvpState === 'declined-invite' && (
          <Modal open layoutId="rsvp">
            <Modal.Content>
              <h2>No worries</h2>
              <p>Thanks for letting us know</p>
              <Modal.Actions>
                <Button primary content="Dismiss" onClick={() => setRsvpState('none')} />
              </Modal.Actions>
            </Modal.Content>
          </Modal>
        )}
        {rsvpState === 'error' && (
          <Modal open layoutId="rsvp">
            <Modal.Content>
              <h2>Sorry</h2>
              <p>We ran into an issue trying to submit your request</p>
              <Modal.Actions>
                <Button content="Dismiss" onClick={() => setRsvpState('none')} />
              </Modal.Actions>
            </Modal.Content>
          </Modal>
        )}
        {!showVideo &&
          (rsvpState === 'in-person' ||
            (rsvpState === 'rsvp' && !config.rsvpForceStream && userRecord?.guests.length)) && (
            <RsvpFlow data={userRecord} onClose={() => setRsvpState('none')} />
          )}
      </AnimatePresence>
    </AnimateSharedLayout>
  );
}
