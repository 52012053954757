import loadable from '@loadable/component';
import { formatRelative } from 'date-fns';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { createSharePost, getSharePosts } from '../app/api';
import store from '../app/store';
import { Portal } from '../app/utils';
import ShareIcon from '../images/shareIcon.svg';
import { addPhoto } from '../s3api';
import Button from './Button';
import Form from './Form';
import Image from './Image';
import Modal from './Modal';
import SectionHeader from './SectionHeader';
import TryEmailModal from './TryEmailModal';
import LiveShareIcon from '../images/liveshareicon.svg';
const Mason = loadable(() => import('react-stone-mason'));

function escapeHtml(str) {
  var div = document.createElement('div');
  div.appendChild(document.createTextNode(str));
  return div.innerHTML;
}

export function Tweet({ name, message, postedAt, email, images }) {
  const __html = useMemo(
    () =>
      message
        ? escapeHtml(message).replace(
            /(https?\:\/\/.*)/g,
            `<a href="$1" target="__blank" rel="noreferrer">$1</a>`
          )
        : undefined,
    [message]
  );

  return (
    <div className="tweet" style={{ textAlign: 'left', padding: '1rem 0.5rem' }}>
      <main className="image">
        <div className="content">
          <h4 style={{ marginBottom: '0.5rem' }}>{name}</h4>
          <h6
            style={{
              marginBottom: '0.5rem',
              color: `var(--text-secondary)`,
              opacity: '75%',
              fontWeight: 'lighter',
              textTransform: 'lowercase',
            }}
          >
            {formatRelative(new Date(postedAt), new Date())}
          </h6>
          <p
            style={{ marginBottom: '0', lineHeight: '1em' }}
            dangerouslySetInnerHTML={{ __html }}
          />
        </div>
        {images?.map(image => (
          <Image src={image} />
        ))}
      </main>
    </div>
  );
}

enum ShareState {
  None,
  CreateModal,
  CreateError,
  Creating,
  Login,
}

export default function Share(props) {
  const [user] = store.use('user');
  const [tweets, setTweets] = useState([]);
  const [state, setState] = store.use('shareState');

  useEffect(() => {
    getSharePosts(setTweets);
  }, []);

  async function handlePost({ message, img }) {
    setState(ShareState.Creating);

    const files = Array.from(img);
    return Promise.all(files.map(addPhoto))
      .then(images => createSharePost({ name: user.Name, ...user, message, images }))
      .then(post => {
        const tweet = post.fields;
        tweet.images = JSON.parse(tweet.images);
        setTweets(tweets => [tweet, ...tweets]);
        setState(ShareState.None);
      })
      .catch(e => {
        console.error(e);
        setState(ShareState.CreateError);
      });
  }

  return (
    <section id="share" data-theme="form">
      {state === ShareState.Creating && (
        <Portal>
          <div data-theme="form" className="loadingContainer">
            <div className="lds-heart">
              <div></div>
            </div>
            <p>Uploading images and posting</p>
          </div>
        </Portal>
      )}
      <br />
      <div className="container"></div>
      <div className="container">
        <div className="share-header">
          <SectionHeader
            icon={ShareIcon}
            to="/#share"
            title="Share a moment"
            content="Is fun allowed?"
          />
          {user?.email ? (
            <Button content="Share" primary onClick={() => setState(ShareState.CreateModal)} />
          ) : (
            <Button content="Login to Share" primary onClick={() => setState(ShareState.Login)} />
          )}
        </div>
        <br />
        <div className="divider" />
        <br />
        {typeof window === 'undefined' ? null : (
          <Mason
            columns={{
              mobile: { query: '(min-width: 33.75em', columns: 1 },
              tablet: { query: '(min-width: 45em)', columns: 2 },
              desktop: { query: '(min-width: 60em)', columns: 3 },
            }}
          >
            {tweets.map(tweet => (
              <Tweet key={tweet.id} {...tweet} />
            ))}
          </Mason>
        )}
      </div>
      <br />
      {[ShareState.CreateModal, ShareState.Creating, ShareState.CreateError].includes(state) && (
        <ShareModal
          error={state === ShareState.CreateError}
          onClose={() => setState(ShareState.None)}
          onPost={handlePost}
          user={user}
        />
      )}
      {state === ShareState.Login && (
        <TryEmailModal
          onAuthenticated={() => setState(ShareState.CreateModal)}
          onCancel={() => setState(ShareState.None)}
        />
      )}
    </section>
  );
}

export function LiveShareButton() {
  const [user] = store.use('user');
  const [shareState, setShareState] = store.use('shareState');
  return (
    <Button
      className="liveshare-button"
      data-theme="green"
      onClick={() => setShareState(user?.email ? ShareState.CreateModal : ShareState.Login)}
    >
      <LiveShareIcon />
      Create Post
    </Button>
  );
}

function ShareModal({ user, onClose, onPost, error }) {
  const { register, formState, handleSubmit } = useForm();
  const { isDirty, dirtyFields } = formState;

  return (
    <Modal open>
      <Form onSubmit={handleSubmit(onPost)}>
        <Modal.Content>
          <h2>Create a Post</h2>
          <p>Posting as {user?.Name}</p>
          {error && (
            <p className="message" data-error>
              Sorry, an unknown error ocurred while posting, would you like to try again?
            </p>
          )}
          <Form.TextArea name="message" label="Message" inputRef={register} />
          <Form.Input
            inputRef={register}
            multiple
            name="img"
            accept="image/*"
            type="file"
            label="Upload Image"
          />
          <Modal.Actions>
            <Button
              content={error ? 'Try Again' : 'Post'}
              primary
              disabled={!isDirty || !Object.keys(dirtyFields).length}
            />
            <Button content="Cancel" onClick={onClose} />
          </Modal.Actions>
        </Modal.Content>
      </Form>
    </Modal>
  );
}
