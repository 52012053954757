import { format } from 'date-fns';
import { graphql, useStaticQuery } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import React from 'react';
import scheduleIcon from '../images/schedule-icon.svg';
import SectionHeader from './SectionHeader';

const query = graphql`
  {
    allScheduleYaml {
      nodes {
        id
        time
        title
        content
      }
    }
    background: file(relativePath: { eq: "schedule-background.png" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

function Block(props) {
  const { title, content, time } = props;

  const formattedContent = { __html: content.replace(/\n/g, '<br />') };

  return (
    <div className="schedule-block">
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <h3 style={{ fontSize: '24px' }}>{title}</h3>
        <h3 style={{ fontSize: '41px', whiteSpace: 'nowrap' }}>
          {format(new Date(time), 'h:mm')}
          <span style={{ fontSize: '19px', whiteSpace: 'nowrap', display: 'inline' }}>
            {(format(new Date(time), 'aaaa') || '').replace(/\./g, '')}
          </span>
        </h3>
      </div>
      <div dangerouslySetInnerHTML={formattedContent} />
    </div>
  );
}

export default function Schedule(props) {
  const data = useStaticQuery(query);
  const events = data.allScheduleYaml.nodes;
  const { background } = data;
  
  return (
    <section data-theme="plum" id="schedule">
      <br />
      <div className="container">
        <SectionHeader
          to="/#schedule"
          icon={scheduleIcon}
          title="Schedule"
          content="What is happening when?"
        />
        <div className="divider" />
        <br />
        <div className="schedule-container">
          {events.map(event => (
            <BackgroundImage
              key={event.id}
              className="schedule-block-image"
              fluid={background.childImageSharp.fluid}
            >
              <Block key={event.id} {...event} />
            </BackgroundImage>
          ))}
        </div>
        <br />
      </div>
    </section>
  );
}
