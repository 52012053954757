import React from 'react';
import SectionHeader from './SectionHeader';
import partyIcon from '../images/wedding-party-icon.svg';
import scheduleIcon from '../images/schedule-icon.svg';
import storyIcon from '../images/our-story-icon.svg';
import registryIcon from '../images/registry-icon.svg';
import rsvpIcon from '../images/rsvp-icon.svg';
import locationIcon from '../images/location.svg';
import aroundTownIcon from '../images/around-town.svg';
import shareIcon from '../images/shareIcon.svg';

export default function HomeMenu({ onRsvp }) {
  return (
    <section data-theme="green" id="nav">
      <br />
      <div className="container">
        <div className="row">
          <div className="col-12">
            <SectionHeader title="What are you looking for?" content="Lets save you some time" />
          </div>
        </div>
        <div className="divider" />
        <div className="row">
          <div className="col-6">
            <SectionHeader onClick={onRsvp} icon={rsvpIcon} title="RSVP" content="Should I go?" />
          </div>
          <div className="col-6">
            <SectionHeader
              to="/#share"
              icon={shareIcon}
              title="Share a Moment"
              content="Should we post something?"
            />
          </div>
          <div className="col-6">
            <SectionHeader
              to="/#location"
              icon={locationIcon}
              title="Location"
              content="Where is what?"
            />
          </div>
          <div className="col-6">
            <SectionHeader
              to="/#party"
              icon={partyIcon}
              title="Wedding Party"
              content="Who is that?"
            />
          </div>
          <div className="col-6">
            <SectionHeader
              to="/#schedule"
              icon={scheduleIcon}
              title="Schedule"
              content="What is happening when?"
            />
          </div>
          <div className="col-6">
            <SectionHeader
              to="/#aroundTown"
              icon={aroundTownIcon}
              title="Around town"
              content="How do I waste an hour?"
            />
          </div>
          <div className="col-6">
            <SectionHeader
              to="/#story"
              icon={storyIcon}
              title="Our Story"
              content="Why are we here?"
            />
          </div>
          <div className="col-6">
            <SectionHeader
              to="/#registry"
              icon={registryIcon}
              title="Registry"
              content="Should we buy something?"
            />
          </div>
        </div>
        <br />
      </div>
    </section>
  );
}
