import React, { useState } from 'react';
import FabIcon from '../images/fab-icon.svg';
import PartyIcon from '../images/wedding-party-icon.svg';
import ScheduleIcon from '../images/schedule-icon.svg';
import StoryIcon from '../images/our-story-icon.svg';
import RegistryIcon from '../images/registry-icon.svg';
import RsvpIcon from '../images/rsvp-icon.svg';
import LocationIcon from '../images/location.svg';
import AroundTownIcon from '../images/around-town.svg';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import { AnimatePresence as AnimateSharedLayout, motion, Variant } from 'framer-motion';

const childVariant = {
  hidden: i => ({
    // y: 25,
    x: 100,
    scale: 0,
    opacity: 0,
    transition: {
      delay: i * 0.075,
    },
  }),
  visible: i => ({
    y: 0,
    x: 0,
    scale: 1,
    opacity: 1,
    transition: {
      delay: (7 - i) * 0.075,
    },
  }),
};

const menuVariant = {
  hidden: {},
  visible: {
    opacity: 1,
    transition: {
      when: 'beforeChildren',
      // staggerChildren: 0.1,
    },
  },
};

const coverVariant = {
  hidden: {
    scale: 0,
    transition: {
      duration: 1,
    },
  },
  visible: {
    scale: 5,
    transition: {
      duration: 1,
    },
  },
};

function MenuItem({ label, icon, to = undefined, order, onClick = undefined }) {
  const Icon = icon;
  let children = (
    <div className="fab-menu-item">
      <div className="text">{label}</div>
      <div style={{ width: 'var(--circle-size)', display: 'flex', justifyContent: 'center' }}>
        <div className="menu-item-icon-container">
          <Icon />
        </div>
      </div>
    </div>
  );

  return (
    <motion.div custom={order} onClick={onClick} variants={childVariant}>
      {to ? <AnchorLink to={to} children={children} /> : children}
    </motion.div>
  );
}

export default function Fab({ onRsvp }) {
  const [open, setOpen] = useState(false);

  return (
    <div className="fab" onClick={() => setOpen(o => !o)} data-theme="fab">
      <AnimateSharedLayout>
        {open && (
          <>
            <motion.div
              initial="hidden"
              exit="hidden"
              animate="visible"
              variants={coverVariant}
              className="fab-shader"
            />
            <motion.div variants={menuVariant} initial="hidden" animate="visible" exit="hidden">
              <MenuItem onClick={onRsvp} order={0} label="RSVP" icon={RsvpIcon} />
              <MenuItem order={1} to="/#location" label="Location" icon={LocationIcon} />
              <MenuItem order={2} to="/#party" label="Wedding Party" icon={PartyIcon} />
              <MenuItem order={3} to="/#schedule" label="Schedule" icon={ScheduleIcon} />
              <MenuItem order={4} to="/#aroundTown" label="Around Town" icon={AroundTownIcon} />
              <MenuItem order={5} to="/#story" label="Our Story" icon={StoryIcon} />
              <MenuItem order={6} to="/#registry" label="Registry" icon={RegistryIcon} />
            </motion.div>
          </>
        )}
      </AnimateSharedLayout>
      <motion.div className="fab-icon" animate={{ rotate: open ? 135 : 0 }}>
        <FabIcon />
      </motion.div>
    </div>
  );
}
