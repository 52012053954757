import { AnimatePresence, AnimateSharedLayout, motion } from 'framer-motion';
import Img from 'gatsby-image';
import React, { useMemo, useState } from 'react';
import { Portal, useUniqueId } from '../app/utils';
import Modal from './Modal';

const imgStyle = { marginBottom: `-6px`, objectFit: 'cover' };

export default function ZoomableImage({ style = {}, ...props }) {
  const id = useUniqueId();
  const [open, setOpen] = useState(false);
  const size = [];

  const sizes = useMemo(() => {
    if (!open) return;
    let aspectRatio = props?.fluid?.aspectRatio;
    if (!aspectRatio && props.src) {
      let image: HTMLImageElement = document.querySelector(`img#${id}`);
      aspectRatio = image.naturalWidth / image.naturalHeight;
    }

    let size = { width: window.innerWidth, height: window.innerWidth / aspectRatio };

    let xScale = window.innerWidth / size.width;
    let yScale = window.innerHeight / size.height;

    let scale = Math.min(yScale, xScale);
    size.width *= scale;
    size.height *= scale;
    return size;
  }, [open]);

  if (typeof window === 'undefined') return null;

  return (
    <AnimateSharedLayout>
      <motion.div
        animate={{ opacity: 1 }}
        initial={{ opacity: 0 }}
        exit={{ opacity: 0 }}
        layoutId={id}
        style={{ cursor: 'pointer', ...style }}
        onClick={() => setOpen(true)}
        className="zoom-image-wrapper"
      >
        {props.fluid ? <Img {...props} /> : <img style={imgStyle} id={id} src={props.src} />}
      </motion.div>
      <Portal>
        <AnimatePresence>
          {open && (
            <Modal
              open
              layoutId={id}
              style={{ border: 'none', cursor: 'pointer', backgroundColor: 'transparent' }}
              onClick={() => setOpen(false)}
            >
              {props.fluid ? <Img {...props} style={sizes} /> : <img style={imgStyle} {...sizes} src={props.src} />}
            </Modal>
          )}
        </AnimatePresence>
      </Portal>
    </AnimateSharedLayout>
  );
}
