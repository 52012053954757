import { AnimateSharedLayout, motion } from 'framer-motion';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { createRsvpData } from '../app/api';
import { DEFAULT_ATTENDEE, Portal } from '../app/utils';

import Button from '../components/Button';
import Form from '../components/Form';
import Modal from '../components/Modal';

const MEAL_OPTIONS = [
  { value: 'normal', text: 'With meat' },
  { value: 'vegetarian', text: 'Vegetarian' },
];

const ATTEND_OPTIONS = [
  { value: 'remotely', text: 'remotely' },
  { value: 'in person', text: 'in person' },
  { value: 'no', text: 'no' },
];

const defaultState = {
  guest: 0,
  guests: [],
};

interface Data {
  [key: string]: any;
}

interface WizardStepProps {
  next: () => any;
  back: () => any;
  submit: () => any;
  complete: () => any;
  hasNext: boolean;
  hasBack: boolean;
  setNextSteps: (steps: any[]) => any;
  data: Data;
  updateData: any;
}

function getInitialData(data) {
  return {
    ...defaultState,
    ...data,
  };
}

type WizardStepElement = (props: WizardStepProps) => JSX.Element;

export default function RsvpFlow(props) {
  const { onClose } = props;
  const [currentStep, setCurrentStep] = useState(0);
  const [data, setData] = useState(() => getInitialData(props.data));
  const [steps, setSteps] = useState<WizardStepElement[]>(() =>
    newGuestSteps(getInitialData(props.data))
  );

  const onSubmit = () => {};

  const CurrentStep = steps[currentStep];

  function onNext() {
    setCurrentStep(step => step + 1);
  }

  function onBack() {
    setCurrentStep(step => step - 1);
  }

  return (
    <Modal open layoutId="rsvp">
      <CurrentStep
        next={onNext}
        hasNext={currentStep < steps.length - 1}
        back={onBack}
        submit={onSubmit}
        complete={onClose}
        hasBack={currentStep > 0}
        setNextSteps={next => setSteps(s => [...s.slice(0, currentStep + 1), ...next])}
        data={data}
        updateData={newData =>
          setData({ ...data, ...(typeof newData === 'function' ? newData(data) : newData) })
        }
      />
    </Modal>
  );
}

const StepContainer = props => <motion.div {...props} />;

function newGuestSteps({ guest, count, guests }): WizardStepElement[] {
  if (guests?.length) {
    return [Summary, Success];
  }
  let steps = [GuestInfo];
  if (guest < count - 1) {
    steps.push(AddAnother);
  } else {
    steps.push(Summary, Success);
  }
  return steps;
}

function Success(props: WizardStepProps) {
  const { complete } = props;

  return (
    <StepContainer>
      <Modal.Content>
        <h2>Thanks for the RSVP!</h2>
        <p>
          We've stored you information and if you need to come back to make any changes feel free to
          use RSVP on the main page
        </p>
        <Modal.Actions>
          <Button primary content="Done" onClick={complete} />
        </Modal.Actions>
      </Modal.Content>
    </StepContainer>
  );
}

function Error(props: WizardStepProps) {
  const { complete } = props;

  return (
    <StepContainer>
      <Modal.Content>
        <h2>Something went wrong</h2>
        <p>
          An unexpected error ocurred.
          <br />
          Please try again later or contact us for help
        </p>
        <Modal.Actions>
          <Button primary content="Close" onClick={complete} />
        </Modal.Actions>
      </Modal.Content>
    </StepContainer>
  );
}

function Summary(props: WizardStepProps) {
  const { next, complete, setNextSteps } = props;
  const [data, setData] = useState(props.data);
  const [expanded, setExpanded] = useState(-1);
  const [currentGuest, setCurrentGuest] = useState(null);
  const [loading, setLoading] = useState(false);

  const onSubmit = () => {
    setLoading(true);
    let minLoadingTime = Date.now() + 3000;
    createRsvpData(data)
      .catch(error => {
        setNextSteps([Error]);
        console.error(error);
      })
      .then(() => {
        let diff = minLoadingTime - Date.now();
        if (diff < 0) return next();
        setTimeout(next, diff);
      });
  };

  const onCancel = () => {
    setExpanded(-1);
  };

  return (
    <StepContainer>
      {loading && (
        <Portal>
          <div data-theme="form" className="loadingContainer">
            <div className="lds-heart">
              <div></div>
            </div>
            <p>Sending RSVP</p>
          </div>
        </Portal>
      )}
      <Modal.Content style={{ display: 'flex', flexDirection: 'column' }}>
        <h2 style={{ marginBottom: '20px' }}>
          {expanded > -1
            ? `${expanded > 0 ? `+${expanded} ` : ''}Guest Details`
            : 'Review Selections'}
        </h2>
        <AnimateSharedLayout>
          <motion.div
            layout
            style={{ overflowY: 'auto', flex: '1 1 auto', padding: '2px', margin: '-2px' }}
          >
            {expanded === -1 &&
              data.guests.map((guest, i) => (
                <div className="review" key={guest.id}>
                  <div>
                    <h3>{guest.name}</h3>
                    <p className="rsvp">{guest.attending}</p>
                    {['no', 'remotely'].includes(guest.attending) ? (
                      <p>Notes: {guest.notes}</p>
                    ) : (
                      <>
                        <p>Meal Type: {guest.meal === 'normal' ? 'with meat' : guest.meal}</p>
                        {guest.restrictions && <p>Dietary Restrictions: {guest.restrictions}</p>}
                      </>
                    )}
                  </div>
                  <button
                    onClick={() => {
                      setExpanded(i);
                      setCurrentGuest(data.guests[i]);
                    }}
                  >
                    edit
                  </button>
                </div>
              ))}
            {expanded > -1 && (
              <>
                <Form.Input
                  label="Name"
                  required
                  value={currentGuest.name}
                  onChange={e => setCurrentGuest({ ...currentGuest, name: e.target.value })}
                />
                <Form.Dropdown
                  options={ATTEND_OPTIONS}
                  label="RSVP"
                  required
                  value={currentGuest.attending}
                  onChange={e => setCurrentGuest({ ...currentGuest, attending: e.target.value })}
                />
                {['no', 'remotely'].includes(currentGuest.attending) ? (
                  <Form.TextArea
                    label="Notes"
                    value={currentGuest.notes}
                    onChange={e => setCurrentGuest({ ...currentGuest, notes: e.target.value })}
                  />
                ) : (
                  <>
                    <Form.Dropdown
                      options={MEAL_OPTIONS}
                      label="Meal Type"
                      required
                      value={currentGuest.meal}
                      onChange={e => setCurrentGuest({ ...currentGuest, meal: e.target.value })}
                    />
                    <Form.TextArea
                      label="Dietary Restrictions"
                      value={currentGuest.restrictions}
                      onChange={e =>
                        setCurrentGuest({ ...currentGuest, restrictions: e.target.value })
                      }
                    />
                  </>
                )}
              </>
            )}
          </motion.div>
          {data.guests.length < data.count && expanded === -1 && (
            <Button
              content="Add +1"
              onClick={() => {
                setCurrentGuest({ ...DEFAULT_ATTENDEE });
                setExpanded(data.guests.length);
              }}
            />
          )}
        </AnimateSharedLayout>
        {expanded > -1 ? (
          <Modal.Actions>
            <Button
              primary
              content="Save"
              onClick={e => {
                e.preventDefault();
                let guests = [
                  ...data.guests.slice(0, expanded),
                  currentGuest,
                  ...data.guests.slice(expanded + 1),
                ];
                setData({ ...data, guests });
                setExpanded(-1);
              }}
            />
            <Button content="Cancel" onClick={onCancel} />
          </Modal.Actions>
        ) : (
          <Modal.Actions>
            <Button
              primary
              disabled={loading}
              content={loading ? `Submitting` : `Submit`}
              onClick={onSubmit}
            />
            <Button content="Cancel" onClick={complete} />
          </Modal.Actions>
        )}
      </Modal.Content>
    </StepContainer>
  );
}

function GuestInfo(props: WizardStepProps) {
  const { back, next, hasNext, hasBack, data, updateData, complete } = props;
  const { register, handleSubmit, errors, watch } = useForm({ defaultValues: DEFAULT_ATTENDEE });
  const watchAttending = watch('attending');

  // function onBack() {
  //   if (data.guest > 0) {
  //     updateData(data => ({ guest: data.guest - 1 }));
  //   }
  //   back();
  // }

  function onSubmit(data) {
    updateData(({ guests: oldGuests, guest }) => {
      let guests = oldGuests.slice();
      guests[guest] = data;
      return { guests };
    });
    next();
  }

  return (
    <StepContainer>
      <Modal.Content>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <h2>{data.guest > 0 && `+${data.guest} `}Guest Details</h2>
          <Form.Input
            error={errors?.name}
            label="Name"
            name="name"
            inputRef={register({ required: true })}
            required
          />
          <Form.Dropdown
            options={ATTEND_OPTIONS}
            label="RSVP"
            name="attending"
            inputRef={register({ required: true })}
          />
          {['no', 'remotely'].includes(watchAttending) ? (
            <Form.TextArea label="Notes" name="notes" inputRef={register} />
          ) : (
            <>
              <Form.Dropdown
                options={MEAL_OPTIONS}
                label="Meal Type"
                name="meal"
                inputRef={register({ required: true })}
                required
              />
              <Form.TextArea label="Dietary Restrictions" name="restrictions" inputRef={register} />
            </>
          )}
          <Modal.Actions>
            <Button primary content="Next" disabled={!hasNext} />
            <Button content="Back" onClick={hasBack ? back : complete} />
          </Modal.Actions>
        </Form>
      </Modal.Content>
    </StepContainer>
  );
}

function AddAnother(props: WizardStepProps) {
  const { back, next, setNextSteps, hasBack, data, updateData } = props;

  function onYes() {
    let { guest, count } = data;
    guest++;
    updateData({ guest });
    setNextSteps(newGuestSteps({ count, guest }));
    next();
  }

  function onNo() {
    setNextSteps([Summary, Success]);
    next();
  }

  return (
    <StepContainer>
      <Modal.Content>
        <h2>Will your +{data.guest + 1} be attending?</h2>
        <p>My records show we're expecting {data.count} of you</p>
        <Modal.Actions>
          <Button primary content="Yes!" onClick={onYes} />
          <Button content="No" onClick={onNo} disabled={!hasBack} />
        </Modal.Actions>
      </Modal.Content>
    </StepContainer>
  );
}
