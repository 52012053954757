import { AnimatePresence, AnimateSharedLayout, motion } from 'framer-motion';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import React, { useState } from 'react';
import PartyToggle from '../images/party-toggle.svg';
import partyIcon from '../images/wedding-party-icon.svg';
import SectionHeader from './SectionHeader';

// getHosts();

const query = graphql`
  query {
    allWeddingPartyYaml {
      nodes {
        id
        name
        image {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        title
        description
      }
    }
  }
`;

const backgroundVariant = {
  visible: {
    height: '100%',
    paddingTop: '60px',
    transition: {
      when: 'beforeChildren',
      type: 'easeInOut',
    },
  },
  hidden: {
    height: '140px',
    paddingTop: '20px',
    transition: {
      when: 'afterChildren',
      type: 'easeInOut',
    },
  },
};

const contentVariant = {
  visible: {
    opacity: 1,
    transition: {
      delay: 0.25,
    },
  },
  hidden: {
    opacity: 0,
  },
};

function Block(props) {
  const { name, title, description, image } = props;
  const [visible, setVisible] = useState(false);

  return (
    <div className="party-member" onClick={() => setVisible(v => !v)}>
      <Img fluid={image.childImageSharp.fluid} />
      <motion.div
        layout
        className="content"
        variants={backgroundVariant}
        initial="hidden"
        animate={visible ? 'visible' : 'hidden'}
      >
        <motion.h2 layout>{name}</motion.h2>
        <motion.p layout>{title}</motion.p>
        <AnimatePresence>
          {visible && (
            <motion.p layout variants={contentVariant}>
              {description}
            </motion.p>
          )}
        </AnimatePresence>
        <motion.div
          animate={{ transform: `rotate(${visible ? 135 : 0}deg)` }}
          style={{ position: 'absolute', right: 0, bottom: 0, margin: '20px', height: '44px' }}
        >
          <PartyToggle />
        </motion.div>
      </motion.div>
    </div>
  );
}

export default function WeddingParty(props) {
  const data = useStaticQuery(query);
  const members = data.allWeddingPartyYaml.nodes;
  return (
    <section {...props} id="party">
      <br />
      <div className="container">
        <SectionHeader to="/#party" icon={partyIcon} title="Wedding Party" content="Who is that?" />
      <div className="divider" />
      <br />
      <AnimateSharedLayout>
        <motion.div className="wedding-party" layout>
          {members.map(member => (
            <Block key={member.id} {...member} />
          ))}
        </motion.div>
      </AnimateSharedLayout>
      </div>
      <br />
    </section>
  );
}
